<template>
  <div class="goodsDetail">
    <div class="banner">
      <!-- <van-swipe class="my-swipe" :autoplay="3000" indicator-color="white"> -->
        <!-- <van-swipe-item v-for="(item,index) in  dateilInfo.main_imgs" :key="index"> -->
            <img :src="dateilInfo.main_imgs" class="main_imgs" alt="" />
        <!-- </van-swipe-item> -->
      <!-- </van-swipe> -->
    </div>
    <div class="content">
      <div class="price">￥{{ dateilInfo.price }}</div>
      <div class="title">{{ dateilInfo.title }}</div>
      <div class="detail_imgs">
        <div class="label"><span>产品详情</span></div>
      </div>
      <div class="info" v-for="(item,index) in dateilInfo.detail_imgs" :key="index">
        <img :src="item.url" class="main_imgs" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { productDetail } from '@/api/studentProduct'
export default {
  data () {
    return {
      dateilInfo: {}
    }
  },
  created () {
    const id = this.$route.query.id
    this.getDetail(id)
  },
  methods: {
    async getDetail (id) {
      const res = await productDetail({ id: id })
      if (res.data.code == 200) {
        this.dateilInfo = res.data.data
      }
    }
  }
}
</script>

<style lang="less" scoped>
.goodsDetail {
  .main_imgs {
    width: 100%;
  }
  .content {
    .price {
      padding: 0 10px;
      color: #b5905a;
      font-size: 22px;
    }
    .title {
      padding: 0 10px;
      margin-top: 10px;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
    }
    .detail_imgs {
      padding: 0 10px;
      margin-top: 15px;
      .label {
        border-bottom: 1px solid #ccc;
        font-size: 16px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .info{
        margin-top: 15px;
    }
  }
}
</style>
